import React, { useCallback, useContext, useState } from "react";
import {
  Avatar,
  CssBaseline,
  Link,
  Paper,
  Box,
  Typography,
  Alert,
  AlertTitle,
  IconButton,
  Collapse,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useHistory } from "react-router-dom";
import PinInput from "react-pin-input";
import LoadingSpinner from "Components/LoadingSpinner";
import configData from "../CONFIGURAZIONE.json";
import { LicenzaContext } from "../Utils/LicenzaContext";

const server = configData.general_api_end_point;

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="http://www.sistemipos.it/">
        Sistemi Pos SRL
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const styles = {
  background: {
    width: "100vw",
    height: "100vh",
    backgroundImage: "url(https://wallup.net/wp-content/uploads/1413914382.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: (theme) => ({
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    opacity: 0.96,
    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  }),
  avatar: {
    m: 2,
    bgcolor: "primary.main",
    width: 56,
    height: 56,
  },
  pinInput: {
    padding: "10px",
    inputStyle: {
      caretColor: "transparent",
      width: 50,
      height: 50,
      borderRadius: "50%",
      border: "3px solid",
      outline: "none",
      textAlign: "center",
      margin: 10,
    },
  },
};

export default function LogIn() {
  const history = useHistory();
  const licenzaContext = useContext(LicenzaContext);
  const [cliente, setCliente] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [pinFocus, setPinFocus] = useState(false);

  const handleComplete = (value) => {
    const body_param = {
      cliente: cliente,
      utente: value,
    };

    setLoading(true);
    axios
      .post(`${server}/login`, body_param)
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          licenzaContext.setLicenza(response.data);
          history.replace(`/${response.data.id_cliente}`);
        }
      })
      .catch((err) => {
        console.error(err);
        setPinFocus(true);
        setLoginError(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <ThemeProvider theme={theme}>
      <LoadingSpinner open={loading} />
      <Box sx={styles.background}>
        <CssBaseline />
        <Paper sx={styles.paper(theme)} elevation={6}>
          <Collapse in={loginError}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="chiudi"
                  color="inherit"
                  size="small"
                  onClick={() => setLoginError(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <AlertTitle>Errore</AlertTitle>
              Codice Cliente o PIN errati
            </Alert>
          </Collapse>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Avatar sx={styles.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h4" gutterBottom>
              My Pos Web
            </Typography>
            <Typography component="h2" variant="h6" gutterBottom>
              Inserisci il Codice Cliente
            </Typography>
            <PinInput
              focus
              length={3}
              secret={false}
              type="numeric"
              style={{ padding: "10px" }}
              inputStyle={{
                ...styles.pinInput.inputStyle,
                borderColor: "red",
              }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value) => setCliente(value)}
            />
            <Typography component="h2" variant="h6" gutterBottom>
              Inserisci il PIN
            </Typography>
            <PinInput
              length={5}
              secret
              secretDelay={1000}
              focus={pinFocus}
              type="numeric"
              style={{ padding: "10px" }}
              inputStyle={{
                ...styles.pinInput.inputStyle,
                borderColor: "#5e07a0",
              }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value) => handleComplete(value)}
            />
            <Box sx={{ mt: 4, textAlign: "center" }}>
              <Copyright />
              <Typography variant="caption" display="block">
                v. 6.5
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
