import { Line, Bar, Pie } from "react-chartjs-2";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect, useContext, useCallback } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Info from "@mui/icons-material/Info";
import Moment from "moment";
import LoadingSpinner from "Components/LoadingSpinner";
import Anime from "react-anime";
import {
  ScontriniMediData,
  ScontriniMediOptions,
} from "Utils/Grafici/Home/ScontriniMedi";

import {
  PresenzeRepartiGiornalieroData,
  PresenzeRepartiGiornalieroOptions,
} from "Utils/Grafici/Home/PresenzeRepartiGiornaliero";
import { getColors, getColor } from "Utils/Funzioni/Grafici";
import {
  TenderGiornalieriData,
  TenderGiornalieriOptions,
} from "Utils/Grafici/Home/TenderGiornalieri";

import {
  IncassoGiornalieroData,
  IncassoGiornalieroOptions,
} from "Utils/Grafici/Home/IncassoGiornaliero";

import {
  IncassoMedioScontriniData,
  IncassoMedioScontriniOptions,
} from "Utils/Grafici/Home/IncassoMedioScontrini";

import {
  PresenzeRepartiAggregatoOptions,
  PresenzeRepartiAggregatoData,
} from "Utils/Grafici/Home/PresenzeRepartiAggregato";

import {
  VenditeAnnualiOptions,
  VenditeAnnualiData,
} from "Utils/Grafici/Home/VenditeAnnuali";

import {
  VenditeSettimanaliOptions,
  VenditeSettimanaliData,
} from "Utils/Grafici/Home/VenditeSettimanali";

import {
  PresenzeSettimanaliOptions,
  PresenzeSettimanaliData,
} from "Utils/Grafici/Home/PresenzeSettimanali";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

import Navbar from "Components/NavBar";
import { DataArraySharp } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
// Pilota per API
import configData from "CONFIGURAZIONE.json";
import CardObiettivoSettore from "Components/CardObiettivoSettore";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { LicenzaContext } from "Utils/LicenzaContext";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsReparto from "Utils/Datagrid/Home/Reparto";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import Myparticles from "Components/MyParticles";
import StarIcon from "@material-ui/icons/Star";
import zIndex from "@material-ui/core/styles/zIndex";

// Configurazione del server dall'endpoint API
const server = configData.general_api_end_point;
// Impostazione del timeout per le richieste axios
axios.defaults.timeout = 300000;

/**
 * Crea i breadcrumbs per la navigazione.
 * @param {string} idCliente - L'ID del cliente.
 * @returns {Array} Array di oggetti breadcrumb.
 */
const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
  ];
};

// Definizione degli stili tramite makeStyles di Material-UI
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    padding: theme.spacing(2),
    zIndex: 1,
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  chart: {
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "100vw",
      height: "50vh",
    },
  },
  chart2: {
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
      height: "80vh",
    },
  },
}));

/**
 * Componente principale per la visualizzazione del report "Vendite Home".
 * Gestisce il caricamento dei dati, la selezione del negozio e il rendering dei grafici.
 *
 * @returns {JSX.Element} Il componente React "VenditeHome".
 */
function VenditeHome() {
  // Accesso al contesto per la licenza
  const licenzaContext = useContext(LicenzaContext);
  const idCliente = licenzaContext.licenza.id_cliente;
  const utente = licenzaContext.licenza.utente;
  const configurazioneReport = licenzaContext.licenza.configurazione.VenditeHome;
  const history = useHistory();

  // Stili e breakpoint per il responsive design
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  // Stati per la visualizzazione e per i dialog
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);

  // Stati per i dati dei grafici
  const [incassoGiornalieroChart, setIncassoGiornalieroChart] = useState({});
  const [scontrinoMedioChart, setScontrinoMedioChart] = useState({});
  const [tenderGiornalieriChart, setTenderGiornalieriChart] = useState({});
  const [presenzeRepartiGiornalieroChart, setPresenzeRepartiGiornaliero] = useState({});
  const [presenzeRepartiAggregatoChart, setPresenzeRepartiAggregato] = useState({});
  const [venditeAnnualiChart, setVenditeAnnualiChart] = useState({});
  const [venditeSettimanaliChart, setVenditeSettimanaliChart] = useState({});
  const [presenzeSettimanaliChart, setPresenzeSettimanaliChart] = useState({});
  const [incassoMedioScontriniChart, setIncassoMedioScontriniChart] = useState({});

  // Stati per i dati settimanali
  const [settimanale, setSettimanale] = useState([]);
  const [settimanalePrecedente, setSettimanalePrecedente] = useState([]);
  const [settimanaleAnnoPrecedente, setSettimanaleAnnoPrecedente] = useState([]);

  // Stati per la gestione dei settori e dei negozi
  const [settori, setSettori] = useState([]);
  const [idNegozi, setIdNegozi] = useState([]);
  const [idNegozio, setIdNegozio] = useState("");
  const [clickedElement, setClickedElement] = useState("");
  const [apriSpinner, setApriSpinner] = useState(false);

  // Stati per la tabella dei reparti
  const [columns, setColumns] = useState(columnsReparto);
  const [dataPresenzeReparti, setPresenzeReparti] = useState([]);

  // Stato per la selezione del tab nell'analisi settimanale
  const [analisiSettimanaleTabValue, setAnalisiSettimanaleTabValue] = useState(0);

  // Formatter per la formattazione dei numeri in formato italiano
  const formatter = new Intl.NumberFormat("it-IT", {
    maximumFractionDigits: 0,
  });

  /**
   * Gestisce l'apertura del dialog per il grafico delle presenze giornaliere.
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  /**
   * Gestisce la chiusura del dialog.
   */
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * Gestisce il cambio di negozio tramite la Select.
   * @param {object} event - Evento di cambio selezione.
   */
  const handleIdNegozioChange = (event) => {
    setIdNegozio(event.target.value);
  };

  /**
   * Carica i negozi associati al cliente tramite API.
   */
  useEffect(() => {
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      if (response.status === 200) {
        const negozi = [];
        response.data.forEach((negozio) => {
          if (
            configurazioneReport.negoziSelectBox.showOnlyNegozi.includes(negozio.id_negozio) ||
            configurazioneReport.negoziSelectBox.showOnlyNegozi.length === 0
          ) {
            negozi.push({
              chiave: negozio.id_negozio,
              valore: negozio.nome_negozio,
            });
          }
        });
        setIdNegozi(negozi);
        if (negozi.length > 0) {
          setIdNegozio(negozi[0].chiave);
        }
      }
    });
  }, [idCliente, utente, configurazioneReport]);

  /**
   * Carica i dati dei settori (obiettivi) per il negozio selezionato.
   */
  useEffect(() => {
    setSettori([]);
    const dataDa = Moment().startOf("month").format("DD/MM/YYYY");
    const dataA = Moment().endOf("month").format("DD/MM/YYYY");
    axios
      .post(`${server}/${idCliente}/negozi/aggregati/settori/obiettivi`, {
        dataDa: dataDa,
        dataA: dataA,
        puntiVendita: [idNegozio],
      })
      .then((response) => {
        setSettori(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idNegozio, idCliente]);

  /**
   * Carica i dati di vendita e presenze per i grafici tramite API.
   * Effettua richieste parallele e aggiorna gli stati corrispondenti.
   */
  useEffect(() => {
    setApriSpinner(true);
    let dataDa = Moment().subtract(1, "months");
    let dataA = Moment().subtract(1, "days");
    const body_param = {
      dataDa: dataDa.format("DD/MM/YYYY"),
      dataA: dataA.format("DD/MM/YYYY"),
    };
    setShow(false);

    axios
      .all([
        axios.post(`${server}/${idCliente}/negozi/${idNegozio}/vendite/aggregati`, body_param),
        axios.post(`${server}/${idCliente}/negozi/${idNegozio}/vendite/aggregati/annuale`, body_param),
        axios.post(`${server}/${idCliente}/negozi/${idNegozio}/vendite/aggregati/settimanale`, body_param),
      ])
      .then(
        axios.spread((response1, response2, response3) => {
          // Estrazione dati di vendita giornaliera e annuale
          const venduto = response1.data;
          const vendutoAnnuale = response2.data;

          // Inizializzazione vettori per i dati
          const numero_scontrini = [];
          const numero_scontrini_non_fidelity = [];
          const numero_scontrini_fidelity = [];
          const incasso_giornaliero = [];
          const giornoAnno = [];
          const tenders = {};
          const reparti = {};
          const aggregatoReparti = {};
          const aggregatoRepartiTabella = [];
          const arrayColoreReparti = [];

          // Elaborazione dei dati per ogni giorno
          Object.values(venduto).forEach((data) => {
            numero_scontrini.push(data["nr_scontrini"]);
            numero_scontrini_non_fidelity.push(data["nr_scontrini"] - data["nr_scontrini_fidelity"]);
            numero_scontrini_fidelity.push(data["nr_scontrini_fidelity"]);
            incasso_giornaliero.push(data["importo"]);
            giornoAnno.push(Moment(data["data"]).format("DD/MM/YYYY"));

            // Elaborazione dati per i tender
            Object.values(data["totali_per_tender"]).forEach((tender) => {
              if (!(tender.Descrizione in tenders)) {
                tenders[tender.Descrizione] = [];
              }
              tenders[tender.Descrizione].push({
                x: Moment(data["data"]).format("DD/MM/YYYY"),
                y: tender.Totale,
              });
            });

            // Elaborazione dati per i reparti
            Object.values(data["importi_presenze_reparti"]).forEach((reparto) => {
              if (!(reparto.Descrizione in reparti)) {
                reparti[reparto.Descrizione] = [];
              }
              reparti[reparto.Descrizione].push({
                x: data["data"],
                y: reparto.Presenze,
              });
            });
          });

          // Elaborazione dati per vendite annuali
          const venditeAnnoAttuale = Object.values(vendutoAnnuale["annoAttuale"]).map((mese) => mese["importo"]);
          const venditeAnnoPrecedente = Object.values(vendutoAnnuale["annoPrecedente"]).map((mese) => mese["importo"]);
          VenditeAnnualiData.datasets[0].data = venditeAnnoAttuale;
          VenditeAnnualiData.datasets[1].data = venditeAnnoPrecedente;

          // Elaborazione dati per vendite settimanali
          const venditeSettimanaliAttuale = [];
          const venditeSettimanaliPrecedente = [];
          const venditeSettimanaliAnnoPrecedente = [];
          const labelsSettimanale = [];

          Object.values(response3.data.settimanaAttuale).forEach((giorno) => {
            venditeSettimanaliAttuale.push(giorno["importo"]);
            labelsSettimanale.push(giorno["giorno"]);
          });
          Object.values(response3.data.settimanaPrecedente).forEach((giorno) => {
            venditeSettimanaliPrecedente.push(giorno["importo"]);
          });
          Object.values(response3.data.settimanaAnnoPrecedente).forEach((giorno) => {
            venditeSettimanaliAnnoPrecedente.push(giorno["importo"]);
          });

          VenditeSettimanaliData.labels = labelsSettimanale;
          VenditeSettimanaliData.datasets[0].data = venditeSettimanaliAttuale;
          VenditeSettimanaliData.datasets[1].data = venditeSettimanaliPrecedente;
          VenditeSettimanaliData.datasets[2].data = venditeSettimanaliAnnoPrecedente;

          // Elaborazione dati per presenze settimanali
          const presenzeSettimanaliAttuale = Object.values(response3.data.settimanaAttuale).map((giorno) => giorno["nr_scontrini"]);
          const presenzeSettimanaliPrecedente = Object.values(response3.data.settimanaPrecedente).map((giorno) => giorno["nr_scontrini"]);
          const presenzeSettimanaliAnnoPrecedente = Object.values(response3.data.settimanaAnnoPrecedente).map((giorno) => giorno["nr_scontrini"]);

          PresenzeSettimanaliData.labels = labelsSettimanale;
          PresenzeSettimanaliData.datasets[0].data = presenzeSettimanaliAttuale;
          PresenzeSettimanaliData.datasets[1].data = presenzeSettimanaliPrecedente;
          PresenzeSettimanaliData.datasets[2].data = presenzeSettimanaliAnnoPrecedente;

          // Elaborazione dati per reparti giornalieri e aggregazione
          PresenzeRepartiGiornalieroData.datasets = [];
          let i = 0;
          Object.entries(reparti).forEach(([reparto, datiReparto]) => {
            // Calcolo aggregato delle presenze per ogni reparto
            aggregatoReparti[reparto] = datiReparto.reduce((sum, presenza) => sum + parseInt(presenza.y), 0);
            aggregatoRepartiTabella.push({
              codice: i,
              descrizione: reparto,
              presenze: aggregatoReparti[reparto],
            });

            const colore = getColor(i);
            arrayColoreReparti.push(colore);
            PresenzeRepartiGiornalieroData.datasets.push({
              type: "bar",
              stack: "Base",
              label: reparto,
              data: datiReparto,
              backgroundColor: colore,
              borderColor: "white",
            });
            i++;
          });

          // Elaborazione dati per i tender giornalieri
          TenderGiornalieriData.datasets = [];
          i = 0;
          Object.entries(tenders).forEach(([descrizione, dati]) => {
            TenderGiornalieriData.datasets.push({
              type: "bar",
              stack: "Base",
              label: descrizione,
              data: dati,
              backgroundColor: getColor(i, 0.6),
              borderColor: "white",
              order: 2,
            });
            i++;
          });
          // Aggiunta della linea per l'incasso giornaliero
          TenderGiornalieriData.datasets.push({
            type: "line",
            fill: false,
            label: "Incasso Giornaliero",
            data: incasso_giornaliero,
            backgroundColor: getColor(100),
            borderColor: getColor(70),
            order: 1,
          });

          // Impostazione dati per il grafico aggregato dei reparti
          PresenzeRepartiAggregatoData.labels = Object.keys(aggregatoReparti);
          PresenzeRepartiAggregatoData.datasets[0].data = Object.values(aggregatoReparti);
          PresenzeRepartiAggregatoData.datasets[0].backgroundColor = getColors(Object.keys(aggregatoReparti).length);

          // Impostazione dati per il grafico Scontrini Medi
          ScontriniMediData.labels = giornoAnno;
          ScontriniMediData.datasets[0].data = numero_scontrini_non_fidelity;
          ScontriniMediData.datasets[1].data = numero_scontrini_fidelity;

          // Impostazione dati per l'incasso giornaliero
          IncassoGiornalieroData.labels = giornoAnno;
          IncassoGiornalieroData.datasets[0].data = incasso_giornaliero;

          // Calcolo e impostazione dati per l'incasso medio per scontrino
          const mediaScontrini = incasso_giornaliero.map((incasso, index) => incasso / numero_scontrini[index]);
          IncassoMedioScontriniData.labels = giornoAnno;
          IncassoMedioScontriniData.datasets[0].data = mediaScontrini;
          IncassoMedioScontriniData.datasets[1].data = numero_scontrini;

          // Aggiornamento degli stati con i dati elaborati
          setPresenzeSettimanaliChart(PresenzeSettimanaliData);
          setVenditeSettimanaliChart(VenditeSettimanaliData);
          setSettimanale(response3.data.settimanaAttuale);
          setSettimanalePrecedente(response3.data.settimanaPrecedente);
          setSettimanaleAnnoPrecedente(response3.data.settimanaAnnoPrecedente);
          setVenditeAnnualiChart(VenditeAnnualiData);
          setPresenzeRepartiAggregato(PresenzeRepartiAggregatoData);
          setIncassoMedioScontriniChart(IncassoMedioScontriniData);
          setIncassoGiornalieroChart(IncassoGiornalieroData);
          setScontrinoMedioChart(ScontriniMediData);
          setTenderGiornalieriChart(TenderGiornalieriData);
          setPresenzeRepartiGiornaliero(PresenzeRepartiGiornalieroData);
          setPresenzeReparti(aggregatoRepartiTabella);
          setApriSpinner(false);
          setShow(true);
        })
      )
      .catch((error) => {
        setApriSpinner(false);
        console.error(error);
      });
  }, [idNegozio, idCliente]);

  /**
   * Funzione di rendering principale della pagina "Home".
   * Restituisce il layout dei grafici e delle informazioni.
   *
   * @returns {JSX.Element|null} Il layout della pagina oppure null per dispositivi non desktop/tablet.
   */
  const HomeRendered = () => {
    if (desktop || tablet) {
      return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          {/* Selezione del Negozio */}
          <Grid item xl={2} md={2} className={classes.container} style={{ marginTop: "6vh" }}>
            <Anime
              easing="easeOutElastic"
              duration={2000}
              direction="alternate"
              loop={true}
              delay={(el, index) => index * 240}
              scale={[0.8, 0.9]}
            >
              <Paper elevation={6}>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={idNegozio}
                  onChange={handleIdNegozioChange}
                  fullWidth
                  disabled={!configurazioneReport.negoziSelectBox.attivo}
                  label="Seleziona Negozio"
                >
                  <MenuItem value="0000">Nessuno</MenuItem>
                  {idNegozi.map((negozio) => (
                    <MenuItem key={negozio.chiave} value={negozio.chiave}>
                      {negozio.valore}
                    </MenuItem>
                  ))}
                </Select>
              </Paper>
            </Anime>
          </Grid>

          {/* Grafico Incasso/Tender Giornaliero */}
          <Grid
            item
            xl={12}
            md={12}
            className={classes.container}
            style={{
              filter: !configurazioneReport.attivo ? "blur(20px)" : "",
              pointerEvents: !configurazioneReport.attivo ? "none" : "",
            }}
          >
            <Container>
              <div style={{ textAlign: "center" }}>
                <Typography
                  sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                  color="text.secondary"
                  gutterBottom
                >
                  Incasso/Tender Giornaliero
                </Typography>
                <Paper elevation={5} className={classes.paper}>
                  {show && (
                    <Bar
                      data={tenderGiornalieriChart}
                      options={TenderGiornalieriOptions}
                      className={classes.chart}
                    />
                  )}
                </Paper>
              </div>
            </Container>
          </Grid>

          {/* Grafico Rapporto Scontrini/Fidelity */}
          <Grid
            item
            xl={6}
            md={6}
            className={classes.container}
            style={{
              filter: !configurazioneReport.attivo ? "blur(20px)" : "",
              pointerEvents: !configurazioneReport.attivo ? "none" : "",
            }}
          >
            <Container>
              <div style={{ textAlign: "center" }}>
                <Typography
                  sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                  color="text.secondary"
                  gutterBottom
                >
                  Rapporto Scontrini/Fidelity
                </Typography>
                <Paper elevation={3} className={classes.paper}>
                  {show && (
                    <Bar
                      data={scontrinoMedioChart}
                      options={ScontriniMediOptions}
                      className={classes.chart}
                    />
                  )}
                </Paper>
              </div>
            </Container>
          </Grid>

          {/* Grafico Val.Medio Scont. / Nr. Scontrini */}
          <Grid
            item
            xl={6}
            md={6}
            className={classes.container}
            style={{
              filter: !configurazioneReport.attivo ? "blur(20px)" : "",
              pointerEvents: !configurazioneReport.attivo ? "none" : "",
            }}
          >
            <Container>
              <div style={{ textAlign: "center" }}>
                <Typography
                  sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                  color="text.secondary"
                  gutterBottom
                >
                  Val.Medio Scont. / Nr. Scontrini
                </Typography>
                <Paper elevation={3} className={classes.paper}>
                  {show && (
                    <Bar
                      data={incassoMedioScontriniChart}
                      options={IncassoMedioScontriniOptions}
                      className={classes.chart}
                    />
                  )}
                </Paper>
              </div>
            </Container>
          </Grid>

          {/* Grafici Importo Annuale e Analisi Settimanale */}
          <Grid
            container
            xl={12}
            md={12}
            className={classes.container}
            direction="row"
            style={{
              filter: !configurazioneReport.attivo ? "blur(20px)" : "",
              pointerEvents: !configurazioneReport.attivo ? "none" : "",
            }}
          >
            <Grid item xl={6} md={6}>
              <Container>
                <div style={{ textAlign: "center" }}>
                  <Typography
                    sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Importo Annuale
                  </Typography>
                  <Paper elevation={3} style={{ padding: "1vw" }}>
                    {show && (
                      <Line
                        options={VenditeAnnualiOptions}
                        data={venditeAnnualiChart}
                        style={{ height: "40vh" }}
                      />
                    )}
                  </Paper>
                </div>
              </Container>
            </Grid>
            <Grid container xl={6} md={6}>
              <Container>
                <div>
                  <Grid item xl={12}>
                    <Typography
                      sx={{ fontSize: 30, fontWeight: "bold", textAlign: "center", color: "#263238" }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Analisi Settimanale
                    </Typography>
                  </Grid>
                  <Paper elevation={3} className={classes.paper}>
                    {show && (
                      <>
                        {/* Intestazione dei giorni */}
                        <Grid container xl={12} justifyContent="space-around">
                          {settimanale.map((giorno, index) => (
                            <Grid key={`giorno-header-${index}`} direction="row" xl={1.5} item container>
                              <Grid xl={12} item>
                                <Typography variant="subtitle1" fontSize={15} fontWeight="bold" textAlign="center">
                                  {giorno.giorno}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                        <Divider orientation="horizontal" />
                        {/* Dati della settimana attuale */}
                        <Grid container xl={12} justifyContent="space-around" sx={{ marginTop: "1vh" }} bgcolor="#e3f2fd">
                          {settimanale.map((giorno, index) => (
                            <Grid key={`settimanale-${index}`} direction="row" xl={1.4} item container>
                              <Grid xl={12} item>
                                <Typography variant="subtitle1" fontSize={13} fontWeight="bold" textAlign="center">
                                  {giorno.data}
                                </Typography>
                              </Grid>
                              <Grid xl={12} item>
                                <Typography variant="subtitle2" fontSize={11} fontWeight="bold">
                                  {giorno.festivita !== "" ? <StarIcon fontSize="small" /> : null}
                                </Typography>
                              </Grid>
                              <Grid xl={12} item>
                                <Typography variant="body1" fontSize={15} fontWeight="bold" textAlign="center">
                                  {formatter.format(giorno.importo)}
                                </Typography>
                              </Grid>
                              <Grid xl={12} item>
                                <Typography variant="body1" fontSize={15} fontWeight="bold" textAlign="center">
                                  ({giorno.nr_scontrini})
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                        <Divider sx={{ marginTop: "2vh" }} orientation="horizontal" />
                        {/* Dati della settimana precedente */}
                        <Grid container xl={12} justifyContent="space-around" sx={{ marginTop: "1vh" }} bgcolor="#bbdefb">
                          {settimanalePrecedente.map((giorno, index) => (
                            <Grid key={`settimanale-pre-${index}`} direction="row" xl={1.4} item container justifyContent="center" alignContent="center">
                              <Grid xl={12} item>
                                <Typography variant="subtitle1" fontSize={13} fontWeight="bold" textAlign="center">
                                  {giorno.data}
                                </Typography>
                              </Grid>
                              <Grid xl={12} item>
                                <Typography variant="subtitle2" fontSize={11} fontWeight="bold">
                                  {giorno.festivita !== "" ? <StarIcon fontSize="small" /> : null}
                                </Typography>
                              </Grid>
                              <Grid xl={12} item>
                                <Typography variant="body1" fontSize={15} fontWeight="bold" textAlign="center">
                                  {formatter.format(giorno.importo)}
                                </Typography>
                              </Grid>
                              <Grid xl={12} item>
                                <Typography variant="body1" fontSize={15} fontWeight="bold" textAlign="center">
                                  ({giorno.nr_scontrini})
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                        <Divider sx={{ marginTop: "2vh" }} orientation="horizontal" />
                        {/* Dati della settimana anno precedente */}
                        <Grid container xl={12} justifyContent="space-around" sx={{ marginTop: "1vh" }} bgcolor="#90caf9">
                          {settimanaleAnnoPrecedente.map((giorno, index) => (
                            <Grid key={`settimanale-anno-pre-${index}`} direction="row" xl={1.4} item container justifyContent="center" alignContent="center">
                              <Grid xl={12} item>
                                <Typography variant="subtitle1" fontSize={13} fontWeight="bold" textAlign="center">
                                  {giorno.data}
                                </Typography>
                              </Grid>
                              <Grid xl={12} item>
                                <Typography variant="subtitle2" fontSize={11} fontWeight="bold">
                                  {giorno.festivita !== "" ? <StarIcon fontSize="small" /> : null}
                                </Typography>
                              </Grid>
                              <Grid xl={12} item>
                                <Typography variant="body1" fontSize={15} fontWeight="bold" textAlign="center">
                                  {formatter.format(giorno.importo)}
                                </Typography>
                              </Grid>
                              <Grid xl={12} item>
                                <Typography variant="body1" fontSize={15} fontWeight="bold" textAlign="center">
                                  ({giorno.nr_scontrini})
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </Grid>
                        {/* Tab per Analisi Importi e Presenze */}
                        <TabContext value={analisiSettimanaleTabValue}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              onChange={(event, newValue) => setAnalisiSettimanaleTabValue(newValue)}
                              aria-label="analisi settimanale tabs"
                              centered
                            >
                              <Tab label="Close" value="0" />
                              <Tab icon={<Info />} label="Analisi Importi" value="1" />
                              <Tab icon={<Info />} label="Analisi Presenze" value="2" />
                            </TabList>
                          </Box>
                          <TabPanel value="1">
                            <Line
                              options={VenditeSettimanaliOptions}
                              data={venditeSettimanaliChart}
                              style={{ height: "35vh" }}
                            />
                          </TabPanel>
                          <TabPanel value="2">
                            <Line
                              options={PresenzeSettimanaliOptions}
                              data={presenzeSettimanaliChart}
                              style={{ height: "35vh" }}
                            />
                          </TabPanel>
                        </TabContext>
                      </>
                    )}
                  </Paper>
                </div>
              </Container>
            </Grid>
          </Grid>

          {/* Sezione Obiettivi Settori */}
          <Grid
            item
            xl={6}
            md={6}
            className={classes.container}
            style={{
              filter: !configurazioneReport.attivo ? "blur(20px)" : "",
              pointerEvents: !configurazioneReport.attivo ? "none" : "",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography
                sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                color="text.secondary"
                gutterBottom
              >
                Obiettivi Settori
              </Typography>
              <Paper elevation={3} className={classes.paper}>
                {show && (
                  <Grid container xl={12} direction="row" spacing={2}>
                    {Object.entries(settori).map(([, settore], index) => (
                      <Grid key={`settore-${index}`} item xl={4}>
                        <CardObiettivoSettore datiSettore={settore} />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Paper>
            </div>
          </Grid>

          {/* Sezione Presenze in Scontrino per Reparto */}
          <Grid
            item
            xl={6}
            md={6}
            className={classes.container}
            style={{
              filter: !configurazioneReport.attivo ? "blur(20px)" : "",
              pointerEvents: !configurazioneReport.attivo ? "none" : "",
            }}
          >
            <div>
              <Typography
                sx={{ fontSize: 30, fontWeight: "bold", color: "#263238" }}
                color="text.secondary"
                gutterBottom
                style={{ textAlign: "center" }}
              >
                Presenze In Scontrino per Reparto
              </Typography>
              <IconButton variant="outlined" onClick={handleClickOpen}>
                <Info />
              </IconButton>
              <Paper elevation={3} className={classes.paper}>
                {show && (
                  <DataGrid
                    style={{ flexGrow: 1, height: "41vh" }}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    getRowId={(row) => row.descrizione}
                    rows={dataPresenzeReparti}
                    columns={columns}
                    disableSelectionOnClick
                    localeText={itIT.props.MuiDataGrid.localeText}
                    loading={apriSpinner}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "presenze", sort: "desc" }],
                      },
                    }}
                  />
                )}
              </Paper>
            </div>
          </Grid>

          {/* Dialog per visualizzare il grafico delle Presenze Giornaliere */}
          <React.Fragment>
            <Dialog open={open} onClose={handleClose} maxWidth="xl">
              <DialogTitle>Presenze Giornaliere</DialogTitle>
              <DialogContent>
                {show && (
                  <Bar
                    data={presenzeRepartiGiornalieroChart}
                    options={PresenzeRepartiGiornalieroOptions}
                    className={classes.chart2}
                  />
                )}
              </DialogContent>
            </Dialog>
          </React.Fragment>
        </Grid>
      );
    }
    return null;
  };

  // Render del componente "VenditeHome"
  return (
    <>
      <main className={classes.content}>
        <LoadingSpinner open={apriSpinner} />
        <div className={classes.toolbar} />
        <div className={classes.root}>
          <Navbar
            idCliente={idCliente}
            breadcrumbsReference={BreadcrumbsReference(idCliente)}
            openVendite={true}
          />
          {HomeRendered()}
          {!configurazioneReport.attivo && (
            <Alert severity="error" sx={{ position: "absolute", marginTop: "11vh", marginLeft: "6vw" }}>
              <AlertTitle>Attenzione</AlertTitle>
              La licenza attuale non permette di visualizzare i dati relativi al report Home
            </Alert>
          )}
        </div>
      </main>
      <Myparticles numberAtoms={60} />
    </>
  );
}

export default VenditeHome;
