import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";

import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import HomeIcon from "@material-ui/icons/Home";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import EuroIcon from "@material-ui/icons/Euro";
import PieChartIcon from "@material-ui/icons/PieChart";
import CustomizedBreadcrumbs from "./BreadCrumbs.js";
import KitchenIcon from "@mui/icons-material/Kitchen";
import Tooltip from "@material-ui/core/Tooltip";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import WarningIcon from "@mui/icons-material/Warning";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import RoomIcon from "@mui/icons-material/Room";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ExtensionIcon from "@mui/icons-material/Extension";
import { LicenzaContext } from "../Utils/LicenzaContext";
import MyDialog from "./MyDialog.js";
import ScaleIcon from "@mui/icons-material/Scale";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Settings from '@mui/icons-material/Settings';
import Paper from "@material-ui/core/Paper";

// Costante fissa per la larghezza del drawer
const drawerWidth = 300;

// Stili per il componente NavBar definiti con makeStyles.
// NON modificare la struttura del CSS per compatibilità con le dipendenze.
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    backgroundColor: "#023047",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#023047",
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // Necessario per posizionare il contenuto sotto l'AppBar.
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

// Definizione delle rotte applicative (non modificare)
const routes = {
  Home: "/",
  Scontrini: "/Scontrini",
  Users: "/users",
};

/**
 * Componente NavBar che renderizza la barra di navigazione superiore e il drawer laterale.
 *
 * @param {Object} props - Proprietà del componente.
 * @param {string} props.idCliente - ID del cliente usato per le rotte.
 * @param {Array} props.breadcrumbsReference - Dati per i breadcrumbs.
 * @param {boolean} [props.openFinanziario] - Stato iniziale del menu Finanziario.
 * @param {boolean} [props.openFasceOrarie] - Stato iniziale del menu Fasce Orarie.
 * @param {boolean} [props.openFidelity] - Stato iniziale del menu Fidelity.
 * @param {boolean} [props.openIncassi] - Stato iniziale del menu Incassi.
 * @param {boolean} [props.openOperatori] - Stato iniziale del menu Operatori.
 * @param {boolean} [props.openGeoPos] - Stato iniziale del menu GeoPos.
 * @param {boolean} [props.openBilance] - Stato iniziale del menu Bilance.
 * @param {boolean} [props.showBilance] - Stato di visibilità iniziale del sottomenu Bilance.
 * @param {boolean} [props.openObiettivi] - Stato iniziale del menu Obiettivi.
 * @param {boolean} [props.openVendite] - Stato iniziale del menu Vendite.
 * @param {boolean} [props.openArticoli] - Stato iniziale del menu Articoli.
 * @returns {JSX.Element} Il componente NavBar renderizzato.
 */
function NavBar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const licenzaContext = useContext(LicenzaContext);
  const configurazioneNavBar = licenzaContext.licenza.configurazione.NavBar;

  // Hooks di stato per la gestione dell'apertura/chiusura dei menu e del drawer
  const [open, setOpen] = useState(false);
  const [openFinanziario, setOpenFinanziario] = useState(props.openFinanziario == null ? false : props.openFinanziario);
  const [openFasceOrarie, setOpenFasceOrarie] = useState(props.openFasceOrarie == null ? false : props.openFasceOrarie);
  const [openFidelity, setOpenFidelity] = useState(props.openFidelity == null ? false : props.openFidelity);
  const [openIncassi, setOpenIncassi] = useState(props.openIncassi == null ? false : props.openIncassi);
  const [openOperatori, setOpenOperatori] = useState(props.openOperatori == null ? false : props.openOperatori);
  const [openGeoPos, setOpenGeoPos] = useState(props.openGeoPos == null ? false : props.openGeoPos);
  const [openBilance, setOpenBilance] = useState(props.openBilance || false);
  const [showBilance, setShowBilance] = useState(props.showBilance || false);
  const [openObiettivi, setOpenObiettivi] = useState(props.openObiettivi == null ? false : props.openObiettivi);
  const [openVendite, setOpenVendite] = useState(props.openVendite || false);
  const [venditeHomeButtonSelected, SetVenditeHomeButtonSelected] = useState(false);
  const [scontriniButtonSelected, SetScontriniButtonSelected] = useState(false);
  const [fasceOrarieButtonSelected, SetFasceOrarieButtonSelected] = useState(false);
  const [dialogModuloDisattivato, setDialogModuloDisattivato] = useState(false);
  const [messaggioModuloDisattivato, setMessaggioModuloDisattivato] = useState("");
  const [openArticoli, setOpenArticoli] = useState(props.openArticoli || false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openAvatar = Boolean(anchorEl);
  const [InfoLabel, setInfoLabel] = useState("");

  let history = useHistory();

  /**
   * Apre il drawer laterale.
   */
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  /**
   * Chiude il drawer laterale.
   */
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Mappa dei colori per i menu (non modificare)
  const colorMenu = {
    Home: "#e1f5fe",
    Scontrini: "#311b92",
    Incassi: "#1a237e",
    "Fasce Orarie": "#006064",
    Finanziario: "#004d40",
    Reparti: "#1b5e20",
    Fidelity: "#33691e",
    GeoPos: "#f57f17",
    Obiettivi: "#0277bd",
    Articoli: "#01579b",
    Operatore: "#01579b",
    Reparto: "#01579b",
  };

  /**
   * Redirige alla home delle vendite.
   */
  const redirectVenditeHome = () => {
    history.push(`/${props.idCliente}`);
  };

  /**
   * Redirige al report Incassi se il modulo è attivo, altrimenti mostra un dialogo.
   */
  const redirectIncassi = () => {
    if (!licenzaContext.licenza.moduli.Incassi) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Incassi non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Incassi`);
    }
  };

  /**
   * Redirige al report Scontrini se il modulo è attivo, altrimenti mostra un dialogo.
   * NOTA: il messaggio di errore utilizza "Incassi", verificare se corretto.
   */
  const redirectScontrini = () => {
    if (!licenzaContext.licenza.moduli.Scontrini) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Incassi non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Scontrini`);
    }
  };

  /**
   * Redirige al report Fasce Orarie se il modulo è attivo.
   */
  const redirectReportFasceOrarie = () => {
    if (!licenzaContext.licenza.moduli.FasceOrarie) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Fasce Orarie non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/FasceOrarie`);
    }
  };

  /**
   * Redirige al report Fasce Orarie Reparti se il modulo è attivo.
   */
  const redirectReportFasceOrarieReparto = () => {
    if (!licenzaContext.licenza.moduli.FasceOrarie) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Fasce Orarie non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/FasceOrarie/Reparti`);
    }
  };

  /**
   * Redirige al report Fasce Orarie Operatori se il modulo è attivo.
   */
  const redirectReportFasceOrarieOperatore = () => {
    if (!licenzaContext.licenza.moduli.FasceOrarie) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Fasce Orarie non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/FasceOrarie/Operatori`);
    }
  };

  /**
   * Redirige al report Finanziario IVA se il modulo è attivo.
   */
  const redirectFinanziarioIva = () => {
    if (!licenzaContext.licenza.moduli.Finanziario) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Finanziario non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Finanziario/Iva`);
    }
  };

  /**
   * Redirige al report Finanziario Tender Pagamento se il modulo è attivo.
   */
  const redirectFinanziarioTenderPagamento = () => {
    if (!licenzaContext.licenza.moduli.Finanziario) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Finanziario non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Finanziario/TenderPagamento`);
    }
  };

  /**
   * Redirige al report Reparti se il modulo è attivo.
   */
  const redirectReparti = () => {
    if (!licenzaContext.licenza.moduli.Reparti) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Reparti non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Reparti`);
    }
  };

  /**
   * Redirige al report Fidelity Anomalie se il modulo è attivo.
   */
  const redirectFidelityAnomalie = () => {
    if (!licenzaContext.licenza.moduli.Fidelity) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Fidelity non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Fidelity/Anomalie`);
    }
  };

  /**
   * Redirige al report Incassi Operatore se il modulo è attivo.
   */
  const redirectIncassiOperatore = () => {
    if (!licenzaContext.licenza.moduli.Operatori) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Operatore non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Operatori/Incassi`);
    }
  };

  /**
   * Redirige al report Impostazioni Operatore se il modulo è attivo.
   */
  const redirectImpostazioniOperatore = () => {
    if (!licenzaContext.licenza.moduli.Operatori) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Operatore non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Operatori/Impostazioni`);
    }
  };

  /**
   * Redirige al report GeoPos Presenze se il modulo è attivo.
   */
  const redirectGeoPosPresenze = () => {
    if (!licenzaContext.licenza.moduli.GeoPosizione) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Geo Posizione non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/GeoPos/Presenze`);
    }
  };

  /**
   * Redirige al report Obiettivi Settori se il modulo è attivo.
   */
  const redirectObiettiviSettori = () => {
    if (!licenzaContext.licenza.moduli.Obiettivi) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Obiettivi non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Obiettivi/Settori`);
    }
  };

  /**
   * Redirige al report Articoli Venduto Giornaliero se il modulo è attivo.
   */
  const redirectArticoliVendutoGiornaliero = () => {
    if (!licenzaContext.licenza.moduli.Articoli) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Articoli non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Articoli/VendutoGiornaliero`);
    }
  };

  /**
   * Redirige al report Articoli Venduto Giornaliero Operatore se il modulo è attivo.
   */
  const redirectArticoliVendutoGiornalieroOperatore = () => {
    if (!licenzaContext.licenza.moduli.Articoli) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Articoli non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Articoli/VendutoGiornaliero/Operatore`);
    }
  };

  /**
   * Redirige al report Articoli Venduto Giornaliero Operatore Scontrino se il modulo è attivo.
   */
  const redirectArticoliVendutoGiornalieroOperatoreScontrino = () => {
    if (!licenzaContext.licenza.moduli.Articoli) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Articoli non è attivo. \n Contattare l'assistenza.");
    } else {
      history.push(`/vendite/${props.idCliente}/Report/Articoli/VendutoGiornaliero/Operatore/Scontrino`);
    }
  };

  /**
   * Redirige al report Bilance Reparto.
   * (Il controllo del modulo Bilance è commentato)
   */
  const redirectBilanceReparto = () => {
    history.push(`/bilance/${props.idCliente}/Report/Reparti`);
  };

  /**
   * Redirige al report Bilance PLU.
   */
  const redirectBilancePLU = () => {
    history.push(`/bilance/${props.idCliente}/Report/PLU`);
  };

  /**
   * Redirige al riepilogo Bilance Fasce Orarie.
   */
  const redirectBilanceFasceOrarie = () => {
    history.push(`/bilance/${props.idCliente}`);
  };

  /**
   * Redirige alla pagina di login.
   */
  const redirectLogIn = () => {
    history.push("/");
  };

  /**
   * Genera le proprietà per l'Avatar a partire dal nome utente.
   *
   * @param {string} name - Nome completo dell'utente.
   * @returns {Object} Proprietà per il componente Avatar.
   */
  function stringAvatar(name) {
    // Presuppone che il nome contenga almeno due parole
    const splitName = name.split(" ");
    const stringedName = splitName[0][0] + (splitName.length > 1 ? splitName[1][0] : "");
    return {
      children: stringedName,
    };
  }

  /**
   * Gestisce il click sull'avatar per aprire il menu account.
   *
   * @param {Object} event - L'evento di click.
   */
  const handleClickAvatar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Chiude il menu account dell'avatar.
   */
  const handleCloseAvatar = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      {/* Dialog per la segnalazione di moduli disattivati */}
      <MyDialog
        open={dialogModuloDisattivato}
        message={messaggioModuloDisattivato}
        onClose={() => setDialogModuloDisattivato(false)}
      />
      <CssBaseline />
      {/* AppBar superiore */}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ width: "100vw", backgroundColor: "#ffb703" }}>
          {/* Bottone per aprire il drawer */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            style={{ color: "black" }}
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          {/* Titolo e sottotitolo dell'app */}
          <Typography variant="h4" style={{ color: "black" }}>
            MyPosWeb
          </Typography>
          <Typography variant="h8" style={{ color: "black" }}>
            by SistemiPos
          </Typography>
          {/* Container per Breadcrumbs e Avatar */}
          <div style={{ width: "100vw" }}>
            <Grid container columns={16}>
              <Grid item xs={15} alignContent={"center"}>
                <CustomizedBreadcrumbs items={props.breadcrumbsReference} />
              </Grid>
              <Grid item xs={1} alignContent="center">
                {/* Avatar e menu account */}
                <IconButton
                  onClick={handleClickAvatar}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    sx={{ bgcolor: deepPurple[800] }}
                    {...stringAvatar(licenzaContext.licenza.nome_cliente)}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openAvatar}
                  onClose={handleCloseAvatar}
                  onClick={handleCloseAvatar}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 50,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={handleCloseAvatar}>
                    <Avatar
                      {...stringAvatar(licenzaContext.licenza.nome_cliente)}
                      style={{ padding: 2 }}
                    />
                    <Typography variant="h6" style={{ marginLeft: 4 }}>
                      {licenzaContext.licenza.nome_cliente}
                    </Typography>
                  </MenuItem>
                  <Divider />
                  <Paper elevation={0} style={{ padding: 10 }}>
                    <Typography variant="h6" style={{ color: "black" }}>
                      Licenza
                    </Typography>
                    <br />
                    <Typography variant="body1" >
                      Scadenza: { (licenzaContext.licenza.scadenza) ? licenzaContext.licenza.scadenza : "N.D." }
                    </Typography>
                    <Typography variant="body1" >
                      Cliente: {licenzaContext.licenza.id_cliente}
                    </Typography>
                    <Typography variant="body1" >
                      Utente: {licenzaContext.licenza.utente}
                    </Typography>
                    <Typography variant="body1" >
                      Punti Vendita in Licenza: {(licenzaContext.licenza.configurazione.VenditeHome.negoziSelectBox.showOnlyNegozi.length == 0) ? `${licenzaContext.licenza.negozi.length}/${licenzaContext.licenza.negozi.length}`: `${licenzaContext.licenza.configurazione.VenditeHome.negoziSelectBox.showOnlyNegozi.length}/${licenzaContext.licenza.negozi.length}`}
                    </Typography>
                    {
                      (licenzaContext.licenza.configurazione.VenditeHome.negoziSelectBox.showOnlyNegozi.length !== 0)
                      ? <Typography variant="body2">Negozi visibili: {licenzaContext.licenza.configurazione.VenditeHome.negoziSelectBox.showOnlyNegozi}</Typography>
                      : <></>
                    }
                  </Paper>
                  <Divider />
                  <MenuItem onClick={handleCloseAvatar} disabled>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Impostazioni
                  </MenuItem>
                  <MenuItem onClick={redirectLogIn}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
      {/* Drawer laterale */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        {/* Header del drawer con bottone per chiuderlo */}
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon style={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon style={{ color: "white" }} />
            )}
          </IconButton>
        </div>
        <Divider />
        <>
          {/* Sezione Vendite */}
          {configurazioneNavBar.Vendite.attivo ? (
            <>
              <Tooltip title="Vendite">
                <ListItem
                  button
                  key={"Vendite"}
                  onClick={() => {
                    setOpenVendite(!openVendite);
                    setShowBilance(!showBilance);
                  }}
                >
                  <ListItemIcon>
                    <Diversity3Icon style={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary={"Vendite"} style={{ color: "white" }} />
                  {openVendite ? (
                    <ExpandLess style={{ color: "white" }} />
                  ) : (
                    <ExpandMore style={{ color: "white" }} />
                  )}
                </ListItem>
              </Tooltip>
              <Collapse in={openVendite} timeout="auto">
                <List component="div" disablePadding style={{ paddingLeft: 10 }}>
                  {configurazioneNavBar.Vendite.items.VenditeHome ? (
                    <Tooltip title="Sommario">
                      <ListItem
                        selected={venditeHomeButtonSelected}
                        button
                        key={"Sommario"}
                        onClick={redirectVenditeHome}
                      >
                        <ListItemIcon>
                          <HomeIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Sommario"} style={{ color: "white" }} />
                      </ListItem>
                    </Tooltip>
                  ) : null}

                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Vendite.items.IncassoGiornaliero ? (
                    <Tooltip title="Incassi">
                      <ListItem button key={"Incassi"} onClick={redirectIncassi}>
                        <ListItemIcon>
                          <PointOfSaleIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Incassi"} style={{ color: "white" }} />
                      </ListItem>
                    </Tooltip>
                  ) : null}

                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Vendite.items.Scontrino ? (
                    <Tooltip title="Scontrini">
                      <ListItem
                        selected={scontriniButtonSelected}
                        button
                        key={"Scontrini"}
                        onClick={redirectScontrini}
                      >
                        <ListItemIcon>
                          <ReceiptLongIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Scontrini"} style={{ color: "white" }} />
                      </ListItem>
                    </Tooltip>
                  ) : null}

                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Vendite.items.IncassoOperatore ||
                  configurazioneNavBar.Vendite.items.ImpostazioniOperatore ? (
                    <>
                      <Tooltip title="Operatori">
                        <ListItem
                          button
                          key={"Operatori"}
                          onClick={() => setOpenOperatori(!openOperatori)}
                        >
                          <ListItemIcon>
                            <Diversity3Icon style={{ color: "white" }} />
                          </ListItemIcon>
                          <ListItemText primary={"Operatori"} style={{ color: "white" }} />
                          {openOperatori ? (
                            <ExpandLess style={{ color: "white" }} />
                          ) : (
                            <ExpandMore style={{ color: "white" }} />
                          )}
                        </ListItem>
                      </Tooltip>
                      <Collapse in={openOperatori} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding style={{ paddingLeft: 10 }}>
                          {configurazioneNavBar.Vendite.items.ImpostazioniOperatore ? (
                            <Tooltip title="Impostazioni">
                              <ListItem
                                selected={fasceOrarieButtonSelected}
                                button
                                key={"Impostazioni"}
                                onClick={redirectImpostazioniOperatore}
                              >
                                <ListItemIcon>
                                  <SettingsIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Impostazioni"} style={{ color: "white" }} />
                              </ListItem>
                            </Tooltip>
                          ) : null}
                          {configurazioneNavBar.Vendite.items.IncassoOperatore ? (
                            <Tooltip title="Incassi">
                              <ListItem
                                selected={fasceOrarieButtonSelected}
                                button
                                key={"Incassi"}
                                onClick={redirectIncassiOperatore}
                              >
                                <ListItemIcon>
                                  <PersonSearchIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Incassi"} style={{ color: "white" }} />
                              </ListItem>
                            </Tooltip>
                          ) : null}
                        </List>
                      </Collapse>
                    </>
                  ) : null}
                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Vendite.items.RepartoFasceOrario ||
                  configurazioneNavBar.Vendite.items.VenditeFasceOrario ||
                  configurazioneNavBar.Vendite.items.OperatoreFasceOrario ? (
                    <>
                      <Tooltip title="Fasce Orarie">
                        <ListItem
                          selected={scontriniButtonSelected}
                          button
                          key={"Finanziario"}
                          onClick={() => setOpenFasceOrarie(!openFasceOrarie)}
                        >
                          <ListItemIcon>
                            <AccessTimeIcon style={{ color: "white" }} />
                          </ListItemIcon>
                          <ListItemText primary={"Fasce Orarie"} style={{ color: "white" }} />
                          {openFasceOrarie ? (
                            <ExpandLess style={{ color: "white" }} />
                          ) : (
                            <ExpandMore style={{ color: "white" }} />
                          )}
                        </ListItem>
                      </Tooltip>
                      <Collapse in={openFasceOrarie} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding style={{ paddingLeft: 10 }}>
                          {configurazioneNavBar.Vendite.items.VenditeFasceOrario ? (
                            <Tooltip title="Andamento Vendita">
                              <ListItem
                                selected={fasceOrarieButtonSelected}
                                button
                                key={"ReportFasceOrarie"}
                                onClick={redirectReportFasceOrarie}
                              >
                                <ListItemIcon>
                                  <AccessTimeIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Andamento Vendita"} style={{ color: "white" }} />
                              </ListItem>
                            </Tooltip>
                          ) : null}
                          {configurazioneNavBar.Vendite.items.RepartoFasceOrario ? (
                            <Tooltip title="Andamento Reparti">
                              <ListItem
                                selected={fasceOrarieButtonSelected}
                                button
                                key={"Tender Pagamento"}
                                onClick={redirectReportFasceOrarieReparto}
                              >
                                <ListItemIcon>
                                  <PieChartIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Andamento Reparti"} style={{ color: "white" }} />
                              </ListItem>
                            </Tooltip>
                          ) : null}
                          {configurazioneNavBar.Vendite.items.OperatoreFasceOrario ? (
                            <Tooltip title="Andamento Operatori">
                              <ListItem
                                selected={fasceOrarieButtonSelected}
                                button
                                key={"Operatori"}
                                onClick={redirectReportFasceOrarieOperatore}
                              >
                                <ListItemIcon>
                                  <PieChartIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Andamento Operatori"} style={{ color: "white" }} />
                              </ListItem>
                            </Tooltip>
                          ) : null}
                        </List>
                      </Collapse>
                    </>
                  ) : null}

                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Vendite.items.FinanziarioIva ||
                  configurazioneNavBar.Vendite.items.FinanziarioTenderPagamento ? (
                    <>
                      <Tooltip title="Finanziario">
                        <ListItem
                          selected={scontriniButtonSelected}
                          button
                          key={"Finanziario"}
                          onClick={() => setOpenFinanziario(!openFinanziario)}
                        >
                          <ListItemIcon>
                            <EuroIcon style={{ color: "white" }} />
                          </ListItemIcon>
                          <ListItemText primary={"Finanziario"} style={{ color: "white" }} />
                          {openFinanziario ? (
                            <ExpandLess style={{ color: "white" }} />
                          ) : (
                            <ExpandMore style={{ color: "white" }} />
                          )}
                        </ListItem>
                      </Tooltip>
                      <Collapse in={openFinanziario} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding style={{ paddingLeft: 10 }}>
                          {configurazioneNavBar.Vendite.items.FinanziarioIva ? (
                            <Tooltip title="Analisi Iva">
                              <ListItem
                                selected={fasceOrarieButtonSelected}
                                button
                                key={"Analisi Iva"}
                                onClick={redirectFinanziarioIva}
                              >
                                <ListItemIcon>
                                  <PieChartIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Analisi Iva"} style={{ color: "white" }} />
                              </ListItem>
                            </Tooltip>
                          ) : null}
                          {configurazioneNavBar.Vendite.items.FinanziarioTenderPagamento ? (
                            <Tooltip title="Tender Pagamento">
                              <ListItem
                                selected={fasceOrarieButtonSelected}
                                button
                                key={"Tender Pagamento"}
                                onClick={redirectFinanziarioTenderPagamento}
                              >
                                <ListItemIcon>
                                  <PieChartIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Tender Pagamento"} style={{ color: "white" }} />
                              </ListItem>
                            </Tooltip>
                          ) : null}
                        </List>
                      </Collapse>
                    </>
                  ) : null}

                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Vendite.items.Reparto ? (
                    <Tooltip title="Reparti">
                      <ListItem
                        selected={venditeHomeButtonSelected}
                        button
                        key={"Reparti"}
                        onClick={redirectReparti}
                      >
                        <ListItemIcon>
                          <KitchenIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Reparti"} style={{ color: "white" }} />
                      </ListItem>
                    </Tooltip>
                  ) : null}

                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Vendite.items.AnomalieFidelity ? (
                    <Tooltip title="Anomalie">
                      <ListItem
                        selected={fasceOrarieButtonSelected}
                        button
                        key={"Anomalie"}
                        onClick={redirectFidelityAnomalie}
                      >
                        <ListItemIcon>
                          <WarningIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Anomalie"} style={{ color: "white" }} />
                      </ListItem>
                    </Tooltip>
                  ) : null}

                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Vendite.items.GeoPosPresenze ? (
                    <Tooltip title="Informazioni Geografiche">
                      <ListItem button key={"Presenze"} onClick={redirectGeoPosPresenze}>
                        <ListItemIcon>
                          <RoomIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"GeoPos"} style={{ color: "white" }} />
                      </ListItem>
                    </Tooltip>
                  ) : null}

                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Vendite.items.ObiettiviReparto ||
                  configurazioneNavBar.Vendite.items.ObiettiviSettore ? (
                    <>
                      <Tooltip title="Obiettivi">
                        <ListItem
                          button
                          key={"Obiettivi"}
                          onClick={() => setOpenObiettivi(!openObiettivi)}
                        >
                          <ListItemIcon>
                            <EmojiEventsIcon style={{ color: "white" }} />
                          </ListItemIcon>
                          <ListItemText primary={"Obiettivi"} style={{ color: "white" }} />
                          {openObiettivi ? (
                            <ExpandLess style={{ color: "white" }} />
                          ) : (
                            <ExpandMore style={{ color: "white" }} />
                          )}
                        </ListItem>
                      </Tooltip>
                      <Collapse in={openObiettivi} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding style={{ paddingLeft: 10 }}>
                          {configurazioneNavBar.Vendite.items.ObiettiviSettore ? (
                            <Tooltip title="Settori">
                              <ListItem button key={"Settori"} onClick={redirectObiettiviSettori}>
                                <ListItemIcon>
                                  <EmojiEventsIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Settori"} style={{ color: "white" }} />
                              </ListItem>
                            </Tooltip>
                          ) : null}
                        </List>
                      </Collapse>
                    </>
                  ) : null}

                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Vendite.items.VendutoGiornalieroArticolo ||
                  configurazioneNavBar.Vendite.items.VendutoGiornalieroArticoloOperatore ||
                  configurazioneNavBar.Vendite.items.VendutoGiornalieroArticoloOperatoreScontrino ? (
                    <>
                      <Tooltip title="Articoli">
                        <ListItem
                          button
                          key={"Articoli"}
                          onClick={() => setOpenArticoli(!openArticoli)}
                        >
                          <ListItemIcon>
                            <ExtensionIcon style={{ color: "white" }} />
                          </ListItemIcon>
                          <ListItemText primary={"Articoli"} style={{ color: "white" }} />
                          {openArticoli ? (
                            <ExpandLess style={{ color: "white" }} />
                          ) : (
                            <ExpandMore style={{ color: "white" }} />
                          )}
                        </ListItem>
                      </Tooltip>
                      <Collapse in={openArticoli} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding style={{ paddingLeft: 10 }}>
                          {configurazioneNavBar.Vendite.items.VendutoGiornalieroArticolo ? (
                            <Tooltip title="Giornaliero">
                              <ListItem
                                button
                                key={"Giornaliero"}
                                onClick={redirectArticoliVendutoGiornaliero}
                              >
                                <ListItemIcon>
                                  <ExtensionIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Giornaliero"} style={{ color: "white" }} />
                              </ListItem>
                            </Tooltip>
                          ) : null}
                          {configurazioneNavBar.Vendite.items.VendutoGiornalieroArticoloOperatore ? (
                            <Tooltip title="Operatori">
                              <ListItem
                                button
                                key={"Operatori"}
                                onClick={redirectArticoliVendutoGiornalieroOperatore}
                              >
                                <ListItemIcon>
                                  <ExtensionIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Operatori"} style={{ color: "white" }} />
                              </ListItem>
                            </Tooltip>
                          ) : null}
                          {configurazioneNavBar.Vendite.items.VendutoGiornalieroArticoloOperatoreScontrino ? (
                            <Tooltip title="Scontrino">
                              <ListItem
                                button
                                key={"Scontrino"}
                                onClick={redirectArticoliVendutoGiornalieroOperatoreScontrino}
                              >
                                <ListItemIcon>
                                  <ExtensionIcon style={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Scontrino"} style={{ color: "white" }} />
                              </ListItem>
                            </Tooltip>
                          ) : null}
                        </List>
                      </Collapse>
                    </>
                  ) : null}
                </List>
              </Collapse>
            </>
          ) : null}
          {/* Sezione Bilance */}
          {configurazioneNavBar.Bilance.attivo ? (
            <>
              <Divider variant="middle" component="li" />
              <Tooltip title="Bilance">
                <ListItem
                  button
                  key={"Bilance"}
                  onClick={() => setOpenBilance(!openBilance)}
                >
                  <ListItemIcon>
                    <ScaleIcon style={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText primary={"Bilance"} style={{ color: "white" }} />
                  {openBilance ? (
                    <ExpandLess style={{ color: "white" }} />
                  ) : (
                    <ExpandMore style={{ color: "white" }} />
                  )}
                </ListItem>
              </Tooltip>

              <Collapse in={openBilance} timeout="auto" unmountOnExit>
                <List component="div" disablePadding style={{ paddingLeft: 10 }}>
                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Bilance.items.FasceOrarie ? (
                    <Tooltip title="Sommario">
                      <ListItem
                        selected={venditeHomeButtonSelected}
                        button
                        key={"Sommario"}
                        onClick={redirectBilanceFasceOrarie}
                      >
                        <ListItemIcon>
                          <HomeIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Sommario"} style={{ color: "white" }} />
                      </ListItem>
                    </Tooltip>
                  ) : null}
                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Bilance.items.BilanceReparto ? (
                    <Tooltip title="Reparti">
                      <ListItem
                        selected={venditeHomeButtonSelected}
                        button
                        key={"Sommario"}
                        onClick={redirectBilanceReparto}
                      >
                        <ListItemIcon>
                          <KitchenIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Reparti"} style={{ color: "white" }} />
                      </ListItem>
                    </Tooltip>
                  ) : null}

                  <Divider variant="middle" component="li" />
                  {configurazioneNavBar.Bilance.items.BilancePLU ? (
                    <Tooltip title="PLU">
                      <ListItem
                        selected={venditeHomeButtonSelected}
                        button
                        key={"Sommario"}
                        onClick={redirectBilancePLU}
                      >
                        <ListItemIcon>
                          <ExtensionIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={"PLU"} style={{ color: "white" }} />
                      </ListItem>
                    </Tooltip>
                  ) : null}
                </List>
              </Collapse>
            </>
          ) : null}
        </>
      </Drawer>
    </div>
  );
}

export default NavBar;
